<template>
	<v-app>
		<v-main>
			<v-container fluid fill-height class="login-container">
				<v-layout align-center justify-center>
					<v-flex xs="12" sm="12" md="12" lg="12" xl="12">
						<v-form lazy-validation ref="form" v-model="valid">
							<v-card class="pa-4 px-2 mx-auto rounded-lg" max-width="440" :class="{'elevation-0': isMobile() }">
								<v-card-text>
									<p class="text-h5 text--primary">Blink Login</p>
									<v-alert dense text type="error" v-text="errors.message" v-if="errors.message"></v-alert>
									<v-text-field label="Username" :rules="rules.username" :error-messages="errors.username" v-model="object.username"></v-text-field>
									<v-text-field type="password" label="Password" :rules="rules.password" :error-messages="errors.password" v-model="object.password" maxlength="20"></v-text-field>
								</v-card-text>
								<v-card-actions>
									<v-btn block rounded large color="primary" class="elevation-0" :ripple="false" :loading="loading" @click.prevent.stop="login">Login</v-btn>
                                </v-card-actions>
							</v-card>
						</v-form>
					</v-flex>
				</v-layout>
			</v-container>
			<access-error-dialog ref="errorDialog"></access-error-dialog>
		</v-main>
	</v-app>
</template>


<script>

import { parseUrlParams } from '@/utils/route'
import { getLocalStorage, addLocalStorage, removeLocalStorage } from '@/utils/auth'
import AccessErrorDialog from '../../shared/AccessErrorDialog.vue'

export default {
	name: 'Login',
	components: {
        accessErrorDialog: AccessErrorDialog
	},
	data() {
		return {
			loading: false,
			valid:true,
			object: {
				username: '',
				password: ''
			},
			rules: {
				username: [
					(value) => !!value || 'Username is required',
					(value) => value.length >= 3 && value.length <= 30 || 'Username must be between 3 and 30 characters'
				],
				password: [
					(value) => !!value || 'Password is required',
					(value) => value.length >= 6 && value.length <= 30 || 'Password must be between 6 and 20 characters'
				]
			},
			errors: {
				message: ''
			},
			params: {}
		}
	},
	computed: {
		form() {
			return this.$refs.form
		},
		errorDialog() {
            return this.$refs.errorDialog
		}
	},
	mounted: function() {
        this.checkErrorDialog()
    },
	methods: {
		login: function() {
            this.checkExpiryTime()
			if(this.form.validate()){
				this.loading = true
				this.object.source = 'epanel'
				this.updateErrorMessage('')
				this.$store.dispatch('auth/login', this.object).then((response) => {
                    this.addStorage('user', response.data.object)
                    this.addStorage('menus', response.data.menus)
					this.$router.push({ path: this.getRedirectUrl() })
					this.loading = false
				}).catch((error) => {
					this.updateErrorMessage(error.message)
					this.loading = false
				})
			}
		},
		updateErrorMessage: function(message) {
			this.errors.message = message
		},
		checkErrorDialog: function() {
            this.params = parseUrlParams(window.location.href)
            const error = this.params.get('e')

            if(error){
                this.errorDialog.open(error)
            }
		},
		getRedirectUrl: function() {
            const redirect = this.params.get('redirect')
            if(redirect){
                return redirect
            }else {
                return '/epanel/dashboard'
            }
		},
		addStorage: function(name, data) {
            addLocalStorage('blink-data', name, data)
		},
		checkExpiryTime: function() {
            const user = getLocalStorage('blink-data', 'user')
            if(user){
                const expiryTime = user["expiry_time"]
                const now = new Date().getTime()
                if(expiryTime && now >= expiryTime){
                    removeLocalStorage('blink-data')
                }
            }
		},
		isMobile: function() {
            return this.$vuetify.breakpoint.mobile
		}
	}
}

</script>


<style scoped>

.login-container {
	min-height: 100%;
	width: 100%;
	overflow: hidden;
}


</style>